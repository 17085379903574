
import Navigation from '@/components/Navigation/navbar/Navigation.vue'
import Toolbar from '@/components/Navigation/toolbar/Toolbar.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Navigation,
    Toolbar
  }
})
export default class ConsolePage extends Vue {

}
