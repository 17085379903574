
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NavigationLink extends Vue {
  @Prop({ required: true })
  private readonly to!: string;

  @Prop({ default: false })
  private readonly external!: boolean;

  @Prop()
  private readonly icon!: string;
}
