
import { Vue, Component } from 'vue-property-decorator'
import ToolbarItem from './ToolbarItem.vue'
import BusinessPicker from '@/components/collections/business/BusinessPicker.vue'
import Badge from '@/components/status/Badge.vue'
import NavigationLink from '@/components/Navigation/toolbar/NavigationLink.vue'
import Config from '@/config'

@Component({
  components: {
    ToolbarItem,
    BusinessPicker,
    Badge,
    NavigationLink
  }
})
export default class Toolbar extends Vue {
  businessPickerOpen = false

  publicUrl = Config.WebsiteUrls.Public
}
